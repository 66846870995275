import { defineStore } from 'pinia';

export const useReferrerStore = defineStore('referrer', {
  state: () => ({
    from: '',
  }),
  actions: {
    setFrom(from: string) {
      this.from = from;
    },
  },
});
